import React, { useEffect, useState } from "react";
import * as C from "./styles";
import background_logo from "../../assets/images/logo-centro-medico.png";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api from "../../services";
import { saveToken } from "../../services/auth";
import CustomAlert from '../../components/Alerts'
import { CpfMask } from "../../components/Masks";

export default function Login() {
  const navigate = useNavigate();
  const [cpfClient, setCpfClient] = useState("");
  const [passWordClient, setPassWordClient] = useState("");
  const [btnIsOk, setBtnIsOk] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState('')

  function handleRegister() {
    navigate("/register/1");
  }

  function handleForgotPassword() {
    navigate("/esqueceu-a-senha");
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  async function handleSubmit() {
    try {
      const res = await api.post("/api/client/login", {
        cpf: cpfClient.replace(/[^0-9s]/g, ""),
        password: passWordClient,
      });

      saveToken(res.data?.token);

      if (res.data.error === "false") {
        setTimeout(() => {
          window.location.href = "/home"
        }, [1000]);
        setAlertType("success");
        setShowAlertOk(true);
      } else {
        setText(res.data.message)
        setAlertType("error");
        setShowAlertOk(true);
      }

    } catch (err) {
      console.log(err);
    }
  }

  function bodyValidation() {
    if (
      cpfClient !== "" &&
      cpfClient !== null &&
      passWordClient !== "" &&
      passWordClient !== null
    ) {
      setBtnIsOk(false);
    } else {
      setBtnIsOk(true);
    }
  }

  useEffect(() => {
    bodyValidation();
  }, [cpfClient, passWordClient]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Bem vindo! 👋🏻</C.Title>
          <C.Text>
            Por favor preencha seu CPF e senha de acesso a sua conta
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              size="small"
              placeholder="000.000.000-00"
              value={CpfMask(cpfClient) ?? cpfClient}
              onChange={(e) => setCpfClient(e.target.value)}
              inputProps={{ maxLength: 14 }}
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <div>
              <label>Senha</label>
              <p onClick={handleForgotPassword}>Esqueceu a senha?</p>
            </div>
            <TextField
              size="small"
              placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
              type="password"
              value={passWordClient}
              onChange={(e) => setPassWordClient(e.target.value)}
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton
              text="Acessar"
              funcao={handleSubmit}
              disabled={btnIsOk}
            />
          </C.ContainerButton>
          <C.RegisterText>
            <p>Não tem cadastro?</p>
            <p id="register" onClick={handleRegister}>
              Cadastre-se
            </p>
          </C.RegisterText>
        </C.Form>
      </C.Content>
      <C.Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Login feito com sucesso!"
              : text?.toString()
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </C.Alert>
    </C.Container>
  );
}
