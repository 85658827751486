import { useState, useEffect } from "react";
import * as C from "./styles";
import background_logo from "../../../assets/images/logo-centro-medico.png";

import { useNavigate, useLocation } from "react-router-dom";
import { StateGlobal } from "../../../context/GlobalContext";

import api from "../../../services/index";
import PrimaryButton from "../../../components/Button";
import CustomAlert from "../../../components/Alerts";

export default function StepVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data_user } = StateGlobal();
  const [btnIsOk, setBtnIsOk] = useState(true);
  const [type, setType] = useState("");
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState("");

  function handleSelect(type) {
    if (type === "email") {
      setType("email");
    } else {
      setType("phone");
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function handleBack() {
    navigate("/register/1");
  }

  async function sendToken() {
    setBtnIsOk(true);
    try {
      const res = await api.post("/api/client/token/send", {
        cpf: data_user?.cpf,
        type,
        content: type === "email" ? data_user?.email : data_user.phone,
      });
      if (res.data.error === "true") {
        setText(res.data.message);
        setAlertType("error");
        setShowAlertOk(true);
      } else {
        navigate("/register/2", { state: { type: type } });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setBtnIsOk(false);
    }
  }

  useEffect(() => {
    if (type === "") {
      setBtnIsOk(true);
    } else {
      setBtnIsOk(false);
    }
  }, [type]);

  return (
    <>
      <C.Bar />
      <C.Container>
        <C.Content>
          <C.Image>
            <img alt="logo" src={background_logo} />
          </C.Image>
          <C.Text>
            Por favor selecione uma das opções abaixo para receber seu token de
            acesso e continuar com o seu cadastro:
          </C.Text>
          <C.ContainerForm>
            <C.ContainerCheckbox>
              <C.RoundedInput
                checked={type === "email"}
                onClick={() => handleSelect("email")}
              />{" "}
              <C.Text style={{ margin: 0 }}>
                Email: <span>{location?.state?.email_label}</span>
              </C.Text>
            </C.ContainerCheckbox>
            <C.ContainerCheckbox>
              <C.RoundedInput
                checked={type === "phone"}
                onClick={() => handleSelect("phone")}
              />{" "}
              <C.Text style={{ margin: 0 }}>
                Telefone:
                <span>{location?.state?.phone_label}</span>
              </C.Text>
            </C.ContainerCheckbox>
          </C.ContainerForm>
          <C.ContainerButton>
            <PrimaryButton
              text="Continuar"
              disabled={btnIsOk}
              funcao={sendToken}
            />

            <PrimaryButton text="Voltar" funcao={handleBack} />
          </C.ContainerButton>
        </C.Content>
        <C.Footer>Centro Médico Sorocaba © 2022</C.Footer>
        <C.Alert>
          <CustomAlert
            type={alertType}
            alertText={text?.toString()}
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </C.Alert>
      </C.Container>
    </>
  );
}
