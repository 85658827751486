import React, { useEffect, useState } from "react";
import * as C from "./styles";
import TextField from "@mui/material/TextField";
import IconBlock from "../../../../assets/svgs/block.svg";
import IconCheck from "../../../../assets/svgs/IconCheck.svg";
import ModalResetPassword from "../../../../components/ModalResetPassword";
import api from "../../../../services";
import { StateGlobal } from "../../../../context/GlobalContext";
import { CircularProgress } from "@mui/material";
import { PhoneMask } from "../../../../components/Masks";
import CustomAlert from '../../../../components/Alerts'

export default function MyProfile() {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(true);
  const { clientData, setClientData } = StateGlobal();
  const [isOk, setIsOk] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  function switchState() {
    setOpen((prevState) => (prevState ? false : true));
  }

  async function putDataPerfil(image) {
    try {
      const response = await api.put("/api/client/update", {
        name: clientData?.name,
        email: clientData?.email,
        phone: clientData?.phone,
      });
      if (response.data.error === "false") {
        setAlertType("success");
        setShowAlertOk(true);
        setProgress(true);
        setTimeout(() => {
          setProgress(false);
        }, [1000]);
      } else {
        setAlertType("error");
        setShowAlertOk(true);
      }
    } catch (err) {
      setAlertType("error");
      setShowAlertOk(true);
    }
  }

  function validateBody() {
    if (
      clientData?.name !== "" &&
      clientData?.name !== null &&
      clientData?.email !== "" &&
      clientData?.email !== null &&
      clientData?.phone !== "" &&
      clientData?.phone !== null
    ) {
      setIsOk(false);
    } else {
      setIsOk(true);
    }
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  useEffect(() => {
    validateBody();
  }, [clientData]);

  useEffect(() => {
    setTimeout(() => {
      setProgress(false);
    }, [1000]);
  });

  return (
    <>
      {progress ? (
        <C.loading>
          <CircularProgress style={{ color: "#0F2554" }} />
        </C.loading>
      ) : (
        <>
          <C.Container>
            <p id="mySchedules">Meu perfil</p>
            <span>Meu ID: {clientData?.cm_id}</span>
          </C.Container>
          <C.ContainerForm>
            <C.SectionInput>
              <label>Nome</label>
              <TextField
                fullWidth={true}
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={clientData?.name}
                onChange={(e) => {
                  setClientData({
                    ...clientData,
                    name: e.target.value,
                  });
                }}
              />
            </C.SectionInput>

            <C.SectionInput>
              <label>E-mail</label>
              <TextField
                fullWidth={true}
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={clientData?.email}
                onChange={(e) => {
                  setClientData({
                    ...clientData,
                    email: e.target.value,
                  });
                }}
              />
            </C.SectionInput>

            <C.SectionInput>
              <label>Telefone</label>
              <TextField
                fullWidth={true}
                type="tell"
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={
                  clientData?.phone
                    ? PhoneMask(clientData?.phone)
                    : clientData?.phone
                }
                onChange={(e) => {
                  setClientData({
                    ...clientData,
                    phone: e.target.value,
                  });
                }}
              />
            </C.SectionInput>

            <C.BtnBlock
              onClick={() => {
                switchState();
              }}
            >
              <img alt="IconBlock" src={IconBlock} width={20} />
              <span>alterar senha de acesso</span>
            </C.BtnBlock>

            {isOk ? (
              <C.BtnAtt2
                disabled={true}
                onClick={() => {
                  // putDataPerfil();
                }}
              >
                <img alt="IconBlock" src={IconCheck} width={20} />
                <span>Atualizar informações</span>
              </C.BtnAtt2>
            ) : (
              <C.BtnAtt
                onClick={() => {
                  putDataPerfil();
                }}
              >
                <img alt="IconBlock" src={IconCheck} width={20} />
                <span>Atualizar informações</span>
              </C.BtnAtt>
            )}
            <C.Alert>
              <CustomAlert
                type={alertType}
                alertText={
                  alertType === "success"
                    ? "Informações atualizadas"
                    : "Erro ao atualizar informações!"
                }
                show={showAlertOk || showAlertError}
                close={() => closeAlerts()}
              />
            </C.Alert>
          </C.ContainerForm>

          {open === true ? (
            <ModalResetPassword open={open} funcao={switchState} />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
