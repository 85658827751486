import React, { useEffect, useState } from "react";
import * as C from "./styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import IconPrint from "../../../../../assets/svgs/printWhite.svg";
import api from "../../../../../services/index";
import TableCell from "@mui/material/TableCell";
import { Eye, Image, Printer } from "react-feather";
import CustomAlert from "../../../../../components/Alerts";
import { CircularProgress, TableBody } from "@mui/material";
import { StateGlobal } from "../../../../../context/GlobalContext";

export default function ResultBlock({
  open,
  data,
  openDetails,
  setShowDocument,
  setTypeDocument,
  setPdfUrl,
}) {
  const [progress, setProgress] = useState(true);
  const [progressPdfAll, setProgressPdfAll] = useState(false);
  const [progressPdfUnit, setProgressPdfUnit] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState("");
  const { block_button, setBlock_button } = StateGlobal();
  const [idRow, setIdRow] = useState("");

  const switchState = () => {
    if (!progress) {
      setProgress(true);
      setTimeout(() => setProgress(false), 750);
    } else {
      setProgress(false);
    }
  };

  const redirect = (resultUrl) => {
    window.open(resultUrl, "_blank");
  };

  const redirectByServiceId = async (row, index, flag) => {
    setIdRow(index);
    setProgressPdfUnit(true);
    try {
      const { data } = await api.post(`/api/client/list/results/single/print`, {
        complemento_atendimento: row.complemento_atendimento,
      });
      if (flag === true) {
        setTypeDocument("pdf");
        setShowDocument(true);
        return setPdfUrl(data.images);
      }
      setTimeout(() => {
        window.open(data.pdf_url, "_blank");
      }, 2000);
    } catch (error) {
      console.error(error);
    } finally {
      setProgressPdfUnit(false);
    }
  };

  const printAll = async (result, flag) => {
    setIdRow(null);
    setProgressPdfAll(true);
    setBlock_button(true);
    try {
      const { data } = await api.post("/api/client/list/results/print", {
        date: result?.date_block,
        atendimento: result?.atendimento,
      });

      if (flag === true) {
        setTypeDocument("pdf");
        setShowDocument(true);
        return setPdfUrl(data.images);
      }

      if (data.pdf_data) {
        // const linkSource = `data:application/pdf;base64,${data.pdf_data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = `Atendimentos.pdf`;
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
        const downloadLink = document.createElement("a");
        downloadLink.href = data.pdf_url; // Use o campo correto aqui, se for data.pdf_url
        downloadLink.download = `Atendimentos.pdf`;
        downloadLink.target = "_blank"; // Abre o link em uma nova aba
        downloadLink.click();
      } else {
        setText(data.message);
        setAlertType("error");
        setShowAlertOk(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setProgressPdfAll(false);
      setBlock_button(false);
    }
  };

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  useEffect(() => {
    switchState();
  }, [open]);

  return (
    <C.Container>
      <C.ContainerHeader>
        <C.Date>
          <p id="Day">{data.registers[0].day}</p>
          <p>{data.registers[0].month_text}</p>
          <p>{data.registers[0].year}</p>
        </C.Date>

        <C.ContentInfo>
          <C.SubContainerInfo>
            <C.InfoList>
              <span id="primaryTitle">Atendimento:</span>
              <span>{data.registers[0].atendimento}</span>
            </C.InfoList>
          </C.SubContainerInfo>
          <C.ContainerAccordion onClick={openDetails}>
            {open ? (
              <IoIosArrowUp style={{ color: "#6E6B7B", fontSize: "25px" }} />
            ) : (
              <IoIosArrowDown style={{ color: "#D8D6DE", fontSize: "25px" }} />
            )}
          </C.ContainerAccordion>
        </C.ContentInfo>
      </C.ContainerHeader>

      <C.ContainerInfor>
        {open && (
          <>
            <TableBody>
              {data.registers.map((row, index) => (
                <C.StyledTableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      borderBottom: 0,
                      width: "100%",
                      fontFamily: "Montserrat",
                      fontWeight: "400",
                      color: "#333",
                      lineHeight: "18px",
                      padding: 5,
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ borderBottom: 0, fontFamily: "Montserrat" }}
                  >
                    {row.type === "pdf" && row.result_url ? (
                      progressPdfUnit && index === idRow ? (
                        // <C.TextLoad>Carregando...</C.TextLoad>
                        <CircularProgress
                          style={{
                            color: "#0F2554",
                            width: "20px",
                            height: "20px",
                            alignSelf: "center",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Eye
                            color="#6E6B7B"
                            onClick={() => {
                              redirectByServiceId(row, index, true);
                            }}
                          />
                          <Printer
                            color="#6E6B7B"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              redirectByServiceId(row, index, false)
                            }
                          />
                        </div>
                      )
                    ) : row.type === "imagem" && row.result_url ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <Eye
                          color="#6E6B7B"
                          onClick={() => {
                            setShowDocument(true);
                            setTypeDocument("imagem");
                          }}
                        /> */}
                        <Image
                          color="#6E6B7B"
                          style={{ cursor: "pointer" }}
                          onClick={() => redirect(row.result_url)}
                        />
                      </div>
                    ) : (
                      <C.Icon>Indisponível</C.Icon>
                    )}
                  </TableCell>
                </C.StyledTableRow>
              ))}
              <C.ContainerBtnPrint>
                <C.BtnPrint
                  onClick={() => printAll(data, true)}
                  disabled={block_button}
                >
                  {progressPdfAll ? (
                    <CircularProgress
                      style={{
                        color: "#FFF",
                        width: "20px",
                        height: "20px",
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <>
                      <Eye
                        color="#ffffff"
                        style={{ marginRight: "10px" }}
                      />
                      <span>Visualizar todos</span>
                    </>
                  )}
                </C.BtnPrint>

                <C.BtnPrint
                  onClick={() => printAll(data)}
                  disabled={block_button}
                >
                  {progressPdfAll ? (
                    <CircularProgress
                      style={{
                        color: "#FFF",
                        width: "20px",
                        height: "20px",
                        alignSelf: "center",
                      }}
                    />
                  ) : (
                    <>
                      <img
                        alt="IconPrint"
                        src={IconPrint}
                        width={20}
                      />
                      <span>Imprimir todos</span>
                    </>
                  )}
                </C.BtnPrint>
              </C.ContainerBtnPrint>
            </TableBody>
          </>
        )}
      </C.ContainerInfor>
      <C.Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Login feito com sucesso!"
              : text?.toString()
          }
          show={showAlertOk || showAlertError}
          close={closeAlerts}
        />
      </C.Alert>
    </C.Container>
  );
}
