import React, { useEffect, useState } from "react";
import * as C from "./styles";
import background_logo from "../../assets/images/logo-centro-medico.png";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/Button";
import { useNavigate } from "react-router-dom";
import api from "../../services";
import CustomAlert from '../../components/Alerts'
import { CpfMask } from "../../components/Masks";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [cpfClient, setCpfClient] = useState("");
  const [btnIsOk, setBtnIsOk] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  function handleLogin() {
    navigate("/");
  }

  async function handleSubmit() {
    setBtnIsOk(true)
    try {
      const res = await api.post("/api/client/password_reset", {
        cpf: cpfClient?.replace(/[^0-9s]/g, "")
      });

      if (res.data.error === "false") {
        setTimeout(() => {
          navigate("/");
        }, [1000]);
        setAlertType("success");
        setShowAlertOk(true);
      } else {
        setAlertType("error");
        setShowAlertOk(true);
      }
      setBtnIsOk(false)
    } catch (err) {
      setBtnIsOk(false)
      console.log(err);
    }
  }

  function bodyValidation() {
    if (
      cpfClient !== "" &&
      cpfClient !== null
    ) {
      setBtnIsOk(false);
    } else {
      setBtnIsOk(true);
    }
  }

  useEffect(() => {
    bodyValidation();
  }, [cpfClient]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Esqueceu sua senha?</C.Title>
          <C.Text>
            Por favor preencha seu CPF de cadastro para enviarmos uma senha temporária.
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              size="small"
              placeholder="Digite seu CPF de cadastro"
              type="cpf"
              value={CpfMask(cpfClient) ?? cpfClient}
              onChange={(e) => setCpfClient(e.target.value)}
              inputProps={{ maxLength: 14 }}
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton
              text="Enviar nova senha"
              funcao={handleSubmit}
              disabled={btnIsOk}
            />
            <C.BackText onClick={handleLogin}>Voltar</C.BackText>
          </C.ContainerButton>
        </C.Form>
      </C.Content>
      <C.Alert>
        <CustomAlert
          type={alertType}
          alertText={
            alertType === "success"
              ? "Uma nova senha foi enviada para o e-mail cadastrado!"
              : "Erro ao solicitar nova senha!"
          }
          show={showAlertOk || showAlertError}
          close={() => closeAlerts()}
        />
      </C.Alert>
    </C.Container>
  );
}
