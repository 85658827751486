import { useEffect, useState } from "react";
import * as C from "./styles";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import PrimaryButton from "../../../components/Button";
import OtpInput from "react-otp-input"; //https://www.npmjs.com/package/react-otp-input
import { useNavigate, useLocation } from "react-router-dom";
import { StateGlobal } from "../../../context/GlobalContext";
import api from "../../../services/index";
import { saveToken, saveUser } from "../../../services/auth";
import CustomAlert from "../../../components/Alerts";

export default function StepTwo() {
  const navigate = useNavigate();
  const location = useLocation();

  const { data_user, setData_user } = StateGlobal();
  const [btnIsOk, setBtnIsOk] = useState(true);
  const [auxTimer, setAuxTimer] = useState(false);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(2 * 60);
  const minutes = Math.floor(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState("");

  function handleNext() {
    // navigate("/register/3");
    window.location.href = "/register/3";
  }

  function handleBack() {
    navigate("/register/validation");
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  async function patientConsultation() {
    setBtnIsOk(true);
    try {
      const res = await api.post("/api/client/token/send", {
        cpf: data_user?.cpf,
        type: location?.state?.type,
        content:
          location?.state?.type === "email"
            ? data_user?.email
            : data_user.phone,
      });
      if (res.data.error === "true") {
        setText(res.data.message);
        setAlertType("error");
        setShowAlertOk(true);
      } else {
        navigate("/register/3");
      }
    } catch (err) {
      console.err(err);
    } finally {
      setBtnIsOk(false);
    }
  }

  async function tokenValidation() {
    try {
      const res = await api.post("/api/client/token/validate", {
        token: data_user?.token,
      });

      if (res.data.error === "false") {
        setAlertType("success");
        setShowAlertOk(true);
        setText(res.data.message);
        setData_user({
          birthday: res.data.user?.birthday,
          cpf: res.data.user?.cpf,
          email: res.data.user?.email,
          id: res.data.user?.id,
          name: res.data.user?.name,
          phone: res.data.user?.phone,
        });
        saveUser(res.data.user);
        saveToken(res.data.user?.token);
        setTimeout(() => {
          handleNext();
        }, [1000]);
      } else {
        setText(res.data.message);
        setAlertType("error");
        setShowAlertOk(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function bodyValidation() {
    if (
      data_user.token !== "" &&
      data_user.token !== null &&
      data_user?.token?.length === 6
    ) {
      setBtnIsOk(false);
    } else {
      setBtnIsOk(true);
    }
  }

  async function ResendToken() {
    setAuxTimer(true);
    patientConsultation();
  }

  useEffect(() => {
    bodyValidation();
  }, [data_user]);

  useEffect(() => {
    if (totalTimeInSeconds === 0) {
      setAuxTimer(false);
      setTotalTimeInSeconds(2 * 60);
      return;
    } else if (auxTimer === true) {
      setTimeout(() => {
        setTotalTimeInSeconds(totalTimeInSeconds - 1);
      }, 1000);
    }
  }, [totalTimeInSeconds, auxTimer]);

  return (
    <>
      <C.Bar />
      <C.Container>
        <C.Content>
          <C.Image>
            <img alt="logo" src={background_logo} />
          </C.Image>
          <C.Text>
            Enviamos em seu{" "}
            {location?.state?.type === "phone" ? "telefone" : "email"} um código
            de validação de 6 números, por favor digite abaixo:
          </C.Text>
          <C.Form>
            <div>
              <OtpInput
                isInputNum={true}
                numInputs={6}
                separator={" "}
                inputStyle={{
                  width: "40px",
                  marginLeft: "0.25rem",
                  marginRight: "0.25rem",
                  height: "50px",
                  fontSize: "2rem",
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
                value={data_user.token}
                onChange={(e) => setData_user({ ...data_user, token: e })}
              />
            </div>
            {auxTimer === false ? (
              <C.UnderlineText onClick={ResendToken}>
                Reenviar Código
              </C.UnderlineText>
            ) : (
              <C.TextDisabled>
                Reenviar Código -{" "}
                <b>
                  {" "}
                  {minutes}:{seconds.toString().padStart(2, "0")}{" "}
                </b>
              </C.TextDisabled>
            )}
          </C.Form>
          <C.ContainerButton>
            <PrimaryButton
              text="Continuar"
              disabled={btnIsOk}
              funcao={tokenValidation}
            />
            <PrimaryButton text="Voltar" funcao={handleBack} />
          </C.ContainerButton>
        </C.Content>
        <C.Alert>
          <CustomAlert
            type={alertType}
            alertText={
              alertType === "success" ? text?.toString() : text?.toString()
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </C.Alert>
        <C.Footer>Centro Médico Sorocaba © 2022</C.Footer>
      </C.Container>
    </>
  );
}
