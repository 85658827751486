import styled from "styled-components";
import TableRow from "@mui/material/TableRow";

export const Container = styled.div`
  width: 94%;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px 0px #00000045;
  border-radius: 10px;
  margin: 10px 0px;
  margin-left: 3%;
  height: auto;
  padding: 10px 0px;
`;

export const Date = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  #Day {
    font-weight: 600;
    font-size: 25px;
  }

  #datePlus {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #de2e2e;
  }

  p {
    font-size: 14px;
    margin: 0px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
`;

export const ContentInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 26px;
  align-items: center;
`;

export const ContainerInfor = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  color: #00000090;
  width: 95%;
  flex-direction: column;
  justify-content: center;
  padding: 0px 10px;

  #TitleStatus {
    display: flex;
    justify-content: space-between;
    height: 30px;

    span {
      padding: 3px 5px;
      background-color: #2ede6a;
      border-radius: 10px;
      width: 90px;
      color: #fff;
      font-size: 10px;
      display: flex;
      justify-content: center;
      text-transform: capitalize;
    }

    p {
      margin: 0px;
    }
  }

  #TypeCard {
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }

  #ValueCardPlus {
    font-weight: 400;
    font-size: 25px;
    color: #2ede6a;
  }

  #forma {
  }
`;

export const Subtitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blck};
`;

export const SubContainerInfo = styled.div`
  display: flex;
`;

export const ContentModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerAccordion = styled.div``;

export const TextLoad = styled.p`
  color: #0f2554;
  font-size: 14px;
  font-weight: 500;
`;

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  #primaryTitle {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    margin: 2px;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const InfMap = styled.div`
  display: flex;
  width: 245px;
  justify-content: space-between;
  padding: 3px 0px;
  color: #08559f;
  font-weight: 600;
  font-size: 10px;
  text-decoration: underline;
  justify-content: flex-end;

  p {
    display: flex;
  }
`;

export const ContainerBtnPrint = styled.div`
  gap: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  height: 30px;
`;

export const BtnPrint = styled.button`
  min-width: 100px;
  width: fit-content;
  padding: 15px;
  height: 30px;
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 10px;
  }
`;

export const loading = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const ContainerRows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const DivRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFF",
    width: "100%",
    fontFamily: "Montserrat",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F2F2F2",
    width: "100%",
    fontFamily: "Montserrat",
  },
}));

export const Icon = styled.span`
  max-width: 108px;
  height: 20px;
  padding: 2px 9px;
  background-color: rgba(51, 51, 51, 0.12);
  border-radius: 10px;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
`;

export const Alert = styled.div`
  width: 100%;
  padding: 0px 30px;
`;
