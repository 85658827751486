import { useState, useEffect } from "react";
import * as C from "./styles";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import { TextField } from "@mui/material";
import PrimaryButton from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { StateGlobal } from "../../../context/GlobalContext";
import { CpfMask } from "../../../components/Masks/index";
import api from "../../../services/index";

export default function StepOne() {
  const navigate = useNavigate();
  const { data_user, setData_user } = StateGlobal();
  const [btnIsOk, setBtnIsOk] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [resMessage, setResMessage] = useState(false);
  const [message, setMessage] = useState("");

  function handleBack() {
    navigate("/");
  }

  async function sendToken() {
    setBtnIsOk(true);
    try {
      const res = await api.post("/api/client/token/verify", {
        cpf: data_user?.cpf,
      });
      if (res?.data.error === "false") {
        navigate("/register/verification", {
          state: {
            email_label: res.data.email_label,
            phone_label: res.data.phone_label,
          },
        });
        setData_user({
          ...data_user,
          email: res.data.email,
          phone: res.data.phone,
        });
        setShowMessage(false);
        setResMessage(false);
      } else if (res?.data?.error_code === 1) {
        setResMessage(false);
        setShowMessage(true);
      } else {
        setShowMessage(false);
        setMessage(res?.data?.message);
        setResMessage(true);
      }
    } catch (err) {
      setShowMessage(true);
    } finally {
      setBtnIsOk(false);
    }
  }

  function bodyValidation() {
    if (data_user.cpf !== "" && data_user.cpf !== null) {
      setBtnIsOk(false);
    } else {
      setBtnIsOk(true);
    }
  }

  useEffect(() => {
    bodyValidation();
  }, [data_user]);

  return (
    <>
      <C.Bar />
      <C.Container>
        <C.Content>
          <C.Image>
            <img alt="logo" src={background_logo} />
          </C.Image>
          <C.Text>
            Para iniciar seu cadastro digite seu telefone ou e-mail de cadastro:
          </C.Text>
          <C.Form>
            <TextField
              size="small"
              placeholder="CPF"
              fullWidth={true}
              type="tel"
              value={data_user?.cpf ? CpfMask(data_user?.cpf) : data_user?.cpf}
              inputProps={{ maxLength: 12 }}
              onChange={(e) =>
                setData_user({ ...data_user, cpf: e.target.value })
              }
            />
            {showMessage && (
              <C.Text style={{ color: "red" }}>
                Ops... não encontramos seus dados em nosso banco de dados, por
                favor entre em contato em nosso{" "}
                <C.Link href="https://centromedicosorocaba.com.br/contato/" target="_blank">canais de atendimento.</C.Link>
              </C.Text>
            )}
            {resMessage && <C.Text style={{ color: "red" }}>{message}</C.Text>}
            <C.ContainerButton>
              <PrimaryButton
                text="Continuar"
                disabled={btnIsOk}
                funcao={sendToken}
              />

              <PrimaryButton text="Voltar" funcao={handleBack} />
            </C.ContainerButton>
            <C.Text>
              Já tem cadastro?{" "}
              <span onClick={handleBack}>Faça login agora</span>
            </C.Text>
          </C.Form>
        </C.Content>
        <C.Footer>Centro Médico Sorocaba © 2022</C.Footer>
      </C.Container>
    </>
  );
}
