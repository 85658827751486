import React, { useEffect, useState } from "react";
import * as C from "./styles";
import SchedulingBlock from "./SchedulingBlock/index";
import IconFilter from "../../../../assets/svgs/IconFilter.svg";
import clearIconX from "../../../../assets/svgs/clearIconX.svg";
import ModalFilterScheduling from "../../../../components/ModalFilterScheduling";
import api from "../../../../services/index";
import { CircularProgress } from "@mui/material";
import { StateGlobal } from "../../../../context/GlobalContext";
import CustomAlert from "../../../../components/Alerts";

export default function Schedules(props) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(true);
  const [schedulingData, setSchedulingData] = useState([]);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState('')
  const { scheduleFilter, setScheduleFilter } = StateGlobal();

  function switchState() {
    setOpen((prevState) => (prevState ? false : true));
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };


  async function getSchedulingData() {
    setProgress(true);
    try {
      const res = await api.post(
        `/api/client/list/schedules/mobile`,
        {
          start: scheduleFilter?.start ? scheduleFilter?.start : "",
          end: scheduleFilter?.end ? scheduleFilter?.end : "",
        }
      );
      if (res.data.error === "true") {
        setText(res.data.message)
        setAlertType("error");
        setShowAlertOk(true);
        setSchedulingData([])
      } else {
        setSchedulingData(res.data);
        setTimeout(() => {
          setProgress(false);
        }, [500]);
      }

    } catch (err) {
      console.log(err);
    } finally {
      setProgress(false)
    }
  }

  function clearFilter() {
    setScheduleFilter({
      start: "",
      end: "",
      onFilter: false,
    });
    setSchedulingData([]);
  }

  useEffect(() => {
    getSchedulingData()
  }, [scheduleFilter.onFilter])

  return (
    <>
      <C.Container>
        {progress ? (
          <C.loading>
            <CircularProgress style={{ color: "#0F2554" }} />
          </C.loading>
        ) : (
          <>
            <C.ContainerTitle>
              <p id="mySchedules">Meus agendamentos</p>
              {scheduleFilter.onFilter === true ? (
                <button
                  id="clearBtn"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  <img alt="iconFilter" src={clearIconX}></img>
                  Remover
                </button>
              ) : (
                <button
                  onClick={() => {
                    switchState();
                  }}
                >
                  <img alt="iconFilter" src={IconFilter}></img>
                  Filtrar
                </button>
              )}
            </C.ContainerTitle>

            <C.Block>
              {schedulingData?.map((item, index) => {
                return (
                  <div>
                    <SchedulingBlock data={item} />
                  </div>
                );
              })}
            </C.Block>
          </>
        )}
        <C.Alert>
          <CustomAlert
            type={alertType}
            alertText={
              alertType === "success"
                ? text?.toString()
                : text?.toString()
            }
            show={showAlertOk || showAlertError}
            close={() => closeAlerts()}
          />
        </C.Alert>
      </C.Container>

      {open === true ? (
        <ModalFilterScheduling open={open} funcao={switchState} />
      ) : (
        ""
      )}
    </>
  );
}
