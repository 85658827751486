import React, { useEffect, useState } from "react";
import * as C from "./styles";
import { BiSearchAlt } from "react-icons/bi";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconPrint from "../../../../assets/svgs/print.svg";
import api from "../../../../services/index";
import { StateGlobal } from "../../../../context/GlobalContext";
import { CircularProgress } from "@mui/material";
import { X } from "react-feather";

export default function Procedures(props) {
  const [valueSearch, setValueSearch] = useState("");
  const { proceduresFilter, setProceduresFilter } = StateGlobal();
  const [proceduresData, setProceduresData] = useState([]);
  const [progress, setProgress] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [idRow, setIdRow] = useState(``);


  async function getProcesuresData() {
    setProgress(true);
    try {
      const res = await api.post(`/api/client/list/procedures/mobile`, {
        content: proceduresFilter.content,
      });

      if (proceduresFilter.onFilter === true) {
        setProceduresData([]);
      }

      setProceduresData((oldState) => [...oldState, ...res.data]);

      setTimeout(() => {
        setProgress(false);
      }, [500]);
    } catch (err) {
      console.log(err);
    }
  }

  async function getUrl(item) {
    setIsLoading(true);

    const res = await api.get(
      `/api/client/list/procedure/pdf/${item.type}/${item.id}`
    );

    setIsLoading(false);

    window.open(`${res.data.url}`, "_blank");
  }

  function clearFilter() {
    setProceduresFilter({
      content: "",
      onFilter: false,
    });
    setProceduresData([]);
  }

  function redirect(url) {
    window.open(`${url}`, "_blank");
  }

  function sendData() {
    setProceduresFilter({
      content: proceduresFilter.content,
      onFilter: true,
    });
  }

  useEffect(() => {
    getProcesuresData();
  }, [proceduresFilter.onFilter]);

  return (
    <>
      <C.Container>
        {progress ? (
          <C.loading>
            <CircularProgress style={{ color: "#0F2554" }} />
          </C.loading>
        ) : (
          <>
            <p id="mySchedules">Nossos procedimentos</p>
            <C.ContainerSearch>
              <FormControl
                sx={{ m: 1, width: "100%", paddingRight: "10px" }}
                variant="outlined"
              >
                <OutlinedInput
                  fullWidth={true}
                  placeholder="Pesquisar"
                  size="small"
                  id="outlined-adornment-weight"
                  value={proceduresFilter.content}
                  onChange={(e) => {
                    setProceduresFilter({
                      ...proceduresFilter,
                      content: e.target.value,
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {proceduresFilter.onFilter === true ? (
                        <C.IconX>
                          <X
                            color="#0F2554"
                            onClick={() => {
                              clearFilter();
                            }}
                          />
                        </C.IconX>
                      ) : (
                        <BiSearchAlt
                          color="#6E6B7B"
                          style={{ fontSize: "25px" }}
                          onClick={() => {
                            sendData();
                          }}
                        />
                      )}
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                />
              </FormControl>
            </C.ContainerSearch>
            <C.ContainerCard>
              {proceduresData.map((item, index) => {
                return (
                  <C.CardPrint key={index}>
                    <span>{item.name}</span>
                    <C.BtnPrint
                      onClick={() => {
                        getUrl(item);
                        setIdRow(item.id);
                      }}
                    >
                      {isLoading && item.id === idRow ? (
                        <CircularProgress
                          style={{ color: "#0F2554", size: "10px" }}
                        />
                      ) : (
                        <img alt="IconPrint" src={IconPrint} />
                      )}
                    </C.BtnPrint>
                  </C.CardPrint>
                );
              })}
            </C.ContainerCard>
          </>
        )}
      </C.Container>
    </>
  );
}
