import styled from "styled-components";

export const Bar = styled.div`
  width: 100%;
  height: 2%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const Container = styled.div`
  height: 98%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  margin: 50px 30px 0px 30px;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  img {
    width: 221px;
    height: auto;
  }
`;

export const Text = styled.p`
  margin-top: 35px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 800;
    margin-left: 15px;
  }
`;

export const TextInformation = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
`;

export const Form = styled.div`
  width: 100%;
  margin-top: 60px;
`;

export const ContainerButton = styled.div`
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Footer = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text_footer};
  position: absolute;
  bottom: 0px;
`;

export const BackText = styled.span`
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-size: 17;
  text-align: center;
  align-self: center;
`;
export const Alert = styled.div`
  width: 100%;
  padding: 0px 30px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  font-weight: bold;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
`;

export const ContainerTextBack = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const TextUnderline = styled.span`
  width: 95%;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.text_login};
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;

export const RoundedInput = styled.input.attrs({ type: "checkbox" })`
  width: 15px;
  height: 15px;
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
