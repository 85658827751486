import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomAlert({
  type = "success",
  alertText,
  show,
  close,
}) {
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => {
    setShowAlert(false);
    close();
  };
  const handleOpen = () => {
    setShowAlert(true);
    const timer = setTimeout(() => {
      handleClose();
    }, 2000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (show === true) handleOpen();
  }, [show]);

  return (
    <div
      style={{
        display: showAlert === true ? "flex" : "none",
        justifyContent: "center",
        marginTop: "30px",
        textAlign: "center",
        position: "absolute",
        left: "10%",
        right: "10%",
        zIndex: "999",
        top: "480px",
      }}
    >
      {showAlert === true ? (
        <Alert
          variant="filled"
          severity={type}
          style={{ width: "100%!important", height: "100%!important" }}
        >
          {alertText}
        </Alert>
      ) : (
        <div
          style={{ width: "100%!important", height: "100%!important" }}
        ></div>
      )}
    </div>
  );
}
