import React, { useEffect, useState } from "react";
import * as C from "./styles";
import ResultBlock from "./ResultBlock/index";
import IconFilter from "../../../../assets/svgs/IconFilter.svg";
import ModalFilterResult from "../../../../components/ModalFilterResult";
import api from "../../../../services/index";
import { CircularProgress } from "@mui/material";
import { StateGlobal } from "../../../../context/GlobalContext";
import clearIconX from "../../../../assets/svgs/clearIconX.svg";
import CustomAlert from "../../../../components/Alerts";
import { PanZoom } from "react-easy-panzoom";

export default function Results({ setShowDocument, showDocument }) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(true);
  const [resultData, setResultData] = useState([]);
  const [alertType, setAlertType] = useState("");
  const [showAlertOk, setShowAlertOk] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [text, setText] = useState("");
  const { resultsFilter, setResultsFilter } = StateGlobal();
  const [typeDocument, setTypeDocument] = useState("");
  const [pdfUrl, setPdfUrl] = useState();
  function switchState() {
    setOpen(!open);
  }

  const closeAlerts = () => {
    setShowAlertOk(false);
    setShowAlertError(false);
  };

  async function getResultData() {
    setProgress(true);
    setResultData([]);
    try {
      const res = await api.post(`/api/client/list/results`, {
        start: resultsFilter.start,
        end: resultsFilter.end,
      });

      if (res.data.error === "true") {
        setText(res.data.message);
        setAlertType("error");
        setShowAlertOk(true);
        setResultData([]);
      }

      const newArray = res?.data;

      newArray?.map((item, index) => {
        if (!newArray[index].open) {
          newArray[index] = {
            ...newArray[index],
            open: false,
          };
        }
      });
      setResultData(newArray);

      setTimeout(() => {
        setProgress(false);
      }, [500]);
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(false);
    }
  }

  function clearFilter() {
    setResultData([]);
    setResultsFilter({
      start: "",
      end: "",
      onFilter: false,
    });
  }

  useEffect(() => {
    getResultData();
  }, [resultsFilter.onFilter]);

  const panzoomWrapperStyle = {
    width: "100%",
    height: "100vh", // Altura fixa para o bloco, ajuste conforme necessário
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Esconder o conteúdo que ultrapassar os limites
    backgroundColor: "#f0f0f0", // Cor de fundo opcional
  };

  const panzoomContainerStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const zoomableImageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "cover", // A imagem cobre todo o bloco, preservando a proporção
  };

  return (
    <>
      {!showDocument ? (
        <C.Container>
          {progress ? (
            <C.Loading>
              <CircularProgress style={{ color: "#0F2554" }} />
            </C.Loading>
          ) : (
            <>
              <C.ContainerTitle>
                <p id="mySchedules">Meus resultados</p>
                {resultsFilter.onFilter === true ? (
                  <button
                    id="clearBtn"
                    onClick={() => {
                      clearFilter();
                    }}
                  >
                    <img
                      alt="iconFilter"
                      src={clearIconX}
                    ></img>
                    Remover
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      switchState();
                    }}
                  >
                    <img
                      alt="iconFilter"
                      src={IconFilter}
                    ></img>
                    Filtrar
                  </button>
                )}
              </C.ContainerTitle>
              <div>
                {resultData.map((result, index) => {
                  function openDetails() {
                    if (resultData[index].open === true) {
                      resultData[index] = {
                        ...resultData[index],
                        open: false,
                      };

                      setResultData((oldState) => [...oldState]);
                    } else {
                      resultData[index] = {
                        ...resultData[index],
                        open: true,
                      };
                      setResultData((oldState) => [...oldState]);
                    }
                  }

                  return (
                    <C.SBlock onClick={() => {}}>
                      <ResultBlock
                        setPdfUrl={setPdfUrl}
                        setTypeDocument={setTypeDocument}
                        setShowDocument={setShowDocument}
                        data={result}
                        open={result.open}
                        openDetails={openDetails}
                      />
                    </C.SBlock>
                  );
                })}
              </div>
            </>
          )}
          <C.Alert>
            <CustomAlert
              type={alertType}
              alertText={
                alertType === "success" ? text?.toString() : text?.toString()
              }
              show={showAlertOk || showAlertError}
              close={() => closeAlerts()}
            />
          </C.Alert>
        </C.Container>
      ) : typeDocument === "pdf" ? (
        <div style={panzoomWrapperStyle}>
          <PanZoom style={panzoomContainerStyle}>
            {pdfUrl.map((item) => {
              return (
                <img
                  style={zoomableImageStyle}
                  alt="Pdfs"
                  src={item}
                />
              );
            })}
          </PanZoom>
        </div>
      ) : (
        <p id="mySchedules">Imagens não encontradas...</p>
      )}

      {open === true ? (
        <ModalFilterResult
          open={open}
          funcao={switchState}
          getResultData={getResultData}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
    </>
  );
}
